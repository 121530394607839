<template>
  <DefaultDialog
    v-if="
      notificationPermissions.isCanCreate && notificationPermissions.isCanRead
    "
    width="50%"
    height="50%"
    buttonText="اضافة"
    title="ارسال اشعار"
    @onCloseDialog="onCloseDialog"
    :customAddButton="true"
    :customSaveButton="true"
  >
    <SendNotification
      slot-scope="{ submit, closeDialog, isEdit, isView, isNew }"
      :submit="submit"
      :closeDialog="closeDialog"
      :isEdit="isEdit"
      :isView="isView"
      :isNew="isNew"
      :afterSubmit="afterSubmit"
      :itemId="contentId"
    />

    <template #addButton="{ on, attrs, click }">
      <v-btn
        color="primary lighten-1"
        class="mr-1"
        v-bind="attrs"
        v-on="on"
        @click="click"
        small
      >
        <v-icon color="#fff">mdi-square-rounded-badge-outline</v-icon>
      </v-btn>
    </template>

    <template #saveButton="{ click, disabled }">
      <v-btn dark text @click="click" :disabled="disabled">
        <v-icon color="#fff">mdi-arrow-up-circle</v-icon>
      </v-btn>
    </template>
  </DefaultDialog>
</template>

<script>
import CRUDMixin from '@/mixins/DefaultDialogCRUD';
import SendNotification from '../curd/SendNotification.vue';
import PermissionMixin from '@/mixins/PermissionMixin';
import { appPermissions } from '@/libs/auth/permissions/appPermissions';

export default {
  props: ['contentId'],
  components: { SendNotification },
  mixins: [
    CRUDMixin(),
    PermissionMixin(appPermissions.notification, {
      name: 'notificationPermissions',
    }),
  ],
};
</script>

<style lang="scss" scoped></style>
