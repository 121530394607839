<template>
  <form>
    <v-container fluid>
      <v-row>
        <v-col cols="12"> </v-col>
      </v-row>
    </v-container>

    <v-row>
      <v-col>
        <span class="title">
          عدد الاشعارات المرسلة سابقاً :
          {{ sendCount }}
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card elevation="1">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="description"
                  label="وصف الاشعار الجديد"
                  required
                  outlined
                  counter
                  height="300"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import DefaultDialogCUDetails from '@/mixins/DefaultDialogCUDetails';

export default {
  data: () => ({
    description: '',
    contentId: '',
  }),
  mixins: [DefaultDialogCUDetails()],
  computed: {
    crudOptions() {
      return {
        postUrl: 'app/notification',
        putUrl: 'app/notification' + this.contentId,
      };
    },
    sendCount() {
      return 10;
    },
  },
  methods: {
    isInvalid() {
      return false;
    },
    postedData() {
      const postData = {
        description: this.description,
        contentId: this.contentId,
      };

      return postData;
    },
  },
  created() {
    this.contentId = this.itemId;
  },
};
</script>

<style lang="scss" scoped></style>
