export class TagUtls {
  static getContentTags(tags) {
    if (!tags || tags.length == 0) return [];
    const contentTags = tags.map(({ id, tag }) => {
      // add created tag to content tags
      if (id) return this._assignTagToContent(tag);
      // create new tag and added to content tags
      return this._createdNewTagForContent(tag);
    });

    return contentTags;
  }
  
  //  "tagId": long,
  static _assignTagToContent(tag) {
    return { tagId: tag.id };
  }

  //  "tagId": null,
  //  "tag": {
  //    "slug": "string",
  //    "tagLanguages": [
  //      {
  //        "name": "string",
  //        "language": "en",
  //      }
  //    ]
  //  },

  static _createdNewTagForContent(tag) {
    const tagLanguage = tag.tagLanguages[0] || {};
    const postNewTag = {
      tagId: tag.id,
      tag: {
        slug: tag.slug,
        tagLanguages: [
          {
            name: tagLanguage.name,
            language: tagLanguage.language,
          },
        ],
      },
    };

    return postNewTag;
  }
}
