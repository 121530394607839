<template>
  <v-combobox
    v-model="model"
    :filter="filter"
    :hide-no-data="!search"
    :items="items"
    :search-input.sync="search"
    hide-selected
    label="الصنف"
    multiple
    small-chips
    outlined
    dense
    hide-details="auto"
    :loading="isLoading || loading"
    :error-messages="errorMessages"
  >
    <!-- options item style -->
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-html="item.text" />
        <v-list-item-subtitle v-html="item.slug" />
      </v-list-item-content>
    </template>

    <!-- options style on no data -->
    <template v-slot:no-data v-if="canCreateNew">
      <v-list-item>
        <span class="subheading ml-2">انشاء</span>
        <v-chip color="teal lighten-3" label small>
          {{ search }}
        </v-chip>
      </v-list-item>
    </template>

    <!-- selected style -->
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        :color="item.id != null ? 'green' : 'teal'"
        :input-value="selected"
        class="white--text"
        label
        small
      >
        <span class="pr-2"> {{ item.text }} </span>
        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import axiosIns from '@/libs/http/http';
import { makeid } from '@/utls/jsCommon';

export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    errorMessages: {},
    contentTypeId: {},
  },
  data: () => ({
    isLoading: false,
    activator: null,
    attach: null,
    editing: null,
    editingIndex: -1,
    items: [
      // {
      //   text: 'item text',
      // },
    ],
    menu: false,
    model: [
      // {
      //   text: 'item text',
      // },
    ],
    x: 0,
    search: null,
    y: 0,
  }),
  computed: {
    canCreateNew() {
      return false;
    },
    modelValue: {
      get() {
        const value = this.value;
        const model = value
          .map(({ categoryId }) => {
            const item = this.getIfIsAlreadyInItems(categoryId);
            if (item) return item;
          })
          .filter((x) => !!x);
        return model;
      },
      set(model) {
        const allIds = model
          .filter((x) => x.text)
          .map((x) => ({ categoryId: x.id }));
        this.$emit('input', allIds);
      },
    },
  },
  methods: {
    async getData() {
      // Items have already been loaded
      // if (this.items.length > 0) return;
      // Items have already been requested
      if (this.isLoading) return;
      else this.isLoading = true;

      const contentTypeId = this.contentTypeId || null;

      // Lazily load input items
      return axiosIns
        .get('app/category/by-filters', {
          params: { Language: 'ar', contentTypeId, maxResultCount: 1000 },
        })
        .then((response) => {
          const responseDataItems = response.data.items;
          this.items = responseDataItems.reduce((p, v) => {
            const { id, slug, categoryLanguages } = v;
            const categoryLanguage = categoryLanguages[0] || {};
            return [
              ...p,
              this.createNewItem({
                id: id,
                text: categoryLanguage.name,
                slug,
              }),
            ];
          }, []);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    createNewItem({ id, text, slug }) {
      return { id, text, slug };
    },
    filter(item, queryText, itemText) {
      const hasValue = (val) => (val != null ? val : '');
      const isThere = (query, val) =>
        val.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1;

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return isThere(query, text);
    },
    getIfIsAlreadyInItems(v) {
      const item = this.items.find((x) => x.id == v);
      return item;
    },
  },
  watch: {
    model(newValue, prev) {
      this.modelValue = newValue;
    },
    contentTypeId(value) {
      if (!value) return;
      this.getData().then(() => {
        this.model = this.modelValue;
      });
    },
  },
};
</script>
