<template>
  <v-combobox
    v-model="model"
    :filter="filter"
    :hide-no-data="!search"
    :items="items"
    :search-input.sync="search"
    hide-selected
    :label="labelText"
    hide-details="auto"
    multiple
    dense
    small-chips
    outlined
    :loading="isLoading"
  >
    <!-- options item style -->
    <template #item="{ item }">
      <span> {{ item.text }}</span>
      <span class="pr-2"> {{ getTagName(item) }} </span>
    </template>

    <!-- options style on no data -->
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading ml-2">انشاء</span>
        <v-chip color="teal lighten-3" label small>
          {{ search }}
        </v-chip>
      </v-list-item>
    </template>

    <!-- selected style -->
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        :color="item.id != null ? 'amber' : 'light-green'"
        :input-value="selected"
        class="black--text"
        label
        small
      >
        <span class="pr-2"> {{ getTagName(item) }} </span>
        <span class="pr-2"> {{ getText(item) }} </span>
        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import axiosIns from '@/libs/http/http';
import { Tag } from '@/model/tags/tag';
import { TagLanguage } from '@/model/tags/tagLanguage';

export default {
  props: {
    selectedLanguage: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    id: {},
    label: {},
  },
  data: () => ({
    isLoading: false,
    activator: null,
    attach: null,
    editing: null,
    editingIndex: -1,
    items: [
      // {
      //   text: 'AH',
      // },
    ],
    menu: false,
    model: [
      // {
      //   text: 'AH',
      // },
    ],
    x: 0,
    search: null,
    y: 0,
  }),
  methods: {
    getTagName(item) {
      const name = Tag.getTagName(item.tag);
      if (!name) return '';
      return name;
    },
    getText(item) {
      const { text: slug } = item;
      return `(${slug})`;
    },
    async getTags(language) {
      // Items have already been loaded
      if (this.items.length > 0) return;
      // Items have already been requested
      if (this.isLoading) return;
      else this.isLoading = true;

      // Lazily load input items
      return axiosIns
        .get('app/tag', {
          params: { language, maxResultCount: 1000 },
        })
        .then((response) => {
          const responseDataItems = response.data.items;
          this.items = responseDataItems.reduce((p, tag) => {
            // TODO : Refactor Table merage Tag-TagLanguagegs
            // TODO : Get only speicic language
            if (tag.tagLanguages.length == 0) return p;

            return [
              ...p,
              this.createItem({
                id: tag.id,
                text: tag.slug,
                tag,
              }),
            ];
          }, []);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    createItem({ id, text, tag }) {
      return { id, text, tag };
    },
    filter(item, queryText, itemText) {
      const hasValue = (val) => (val != null ? val : '');
      const isThere = (query, val) =>
        val.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1;

      const slug = hasValue(item.text);
      const name = hasValue(this.getTagName(item));
      const query = hasValue(queryText);

      return isThere(query, slug) || isThere(query, name);
    },
    getIfIsAlreadyInItems(id) {
      return this.items.find((x) => x.id == id);
    },
  },
  computed: {
    labelText() {
      return this.label || 'المفاتيح';
    },
    modelValue: {
      get() {
        const value = this.value;
        const model = value
          .map((tagModel) => {
            const { tag } = tagModel;
            if (!tag.id) return tagModel;
            const item = this.getIfIsAlreadyInItems(tag.id);
            if (item) return item;
          })
          .filter((x) => !!x);
        return model;
      },
      set(model) {
        this.$emit('input', model);
      },
    },
  },
  mounted() {
    this.getTags(this.selectedLanguage).then(() => {
      this.model = this.modelValue;
    });
  },
  watch: {
    model(val, prev) {
      if (val.length === prev.length) return;
      // check is item new or already created
      this.model = val.map((v) => {
        // return already created
        if (typeof v !== 'string') return v;

        // create new and return it
        var tagLanguage = new TagLanguage(v, this.selectedLanguage);
        var tag = new Tag({ tagLanguages: [tagLanguage] });

        const newItem = this.createItem({
          id: tag.id,
          text: tag.slug,
          tag,
        });
        return newItem;
      });

      this.modelValue = this.model;
    },
    value(val) {
      this.model = this.modelValue;
    },
  },
};
</script>
