<template>
  <v-card
    elevation="2"
    color="background"
    class="d-flex flex-column"
    style="height: 100%"
  >
    <v-card-title>
      {{ dynamicTitle }}
      <v-spacer></v-spacer>

      <DefaultDialog
        :showAddButton="isCanCreate"
        buttonText="اضافة"
        title="اضافة محتوى"
        :openToEdit="openToEdit"
        editTitle="تعديل محتوى"
        :openToView="openToView"
        viewTitle="عرض محتوى"
        @onCloseDialog="onCloseDialog"
        :toggleSize="true"
      >
        <DynamicContentDetail
          slot-scope="slotProps"
          :submitWithClose="slotProps.submitWithClose"
          :submitWithNew="slotProps.submitWithNew"
          :submitWithoutClose="slotProps.submitWithoutClose"
          :setItemID="setItemID"
          :submit="slotProps.submit"
          :closeDialog="slotProps.closeDialog"
          :afterSubmit="getData"
          :itemId="itemId"
          :isEdit="slotProps.isEdit"
          :isView="slotProps.isView"
          :isNew="slotProps.isNew"
          :schemaTypeId="contentTypeId"
        />
      </DefaultDialog>
    </v-card-title>
    <v-card-text
      class="d-flex flex-column justify-space-between"
      style="flex: 1; min-height: 0"
    >
      <!-- Filters -->
      <v-row style="flex: 0">
        <v-col class="d-flex">
          <languages-filter @change="changeLanguage" ref="languagesFilterRef" />
          <div class="mx-2" />
          <deleted-filter @change="changeIsDeleted" ref="deletedFilterRef" />
          <div class="mx-2" />
          <feature-sticky-filter
            @change="changeIsFeatureIsSticky"
            ref="featureStickyFilterRef"
          />
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="filters.title"
            dense
            flat
            hide-details="auto"
            label="بحث"
            outlined
            prepend-inner-icon="mdi-magnify"
            class="mr-1"
            solo
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Grid -->
      <v-row class="mt-0" style="min-height: 0">
        <v-col cols="12" style="flex: auto; min-height: 0; height: 100%">
          <v-data-table
            class="elevation-1"
            :headers="headers"
            :items="pages"
            :items-per-page="options.pageSize"
            :loading="$store.state.loading.show"
            :ripple="false"
            fixed-header
            height="100%"
            style="overflow: auto; height: 100%"
            hide-default-footer
            loading-text="جار التحميل..."
            no-data-text="لا يوجد"
          >
            <template v-slot:[`item.categoryNames`]="{ item }">
              <v-chip
                v-for="(name, index) in item.categoryNames"
                :key="index"
                class="ml-1"
                label
                small
              >
                {{ name }}
              </v-chip>
            </template>

            <template v-slot:[`item.publishDate`]="{ item }">
              <span dir="ltr">{{ $service.formatDate(item.publishDate) }}</span>
            </template>

            <template v-slot:[`item.controls`]="props">
              <template v-if="props.item.isDeleted">
                <v-btn
                  small
                  color="red"
                  @click="cancelDeleteRow(props.item)"
                  class="mr-1"
                >
                  <v-icon color="#fff">mdi-arrow-u-left-bottom</v-icon>
                </v-btn>
              </template>

              <template v-else>
                <!-- <v-btn
              small
              color="#78b200"
              @click="viewRow(props.item)"
              class="mr-1"
            >
              <v-icon color="#fff">mdi-eye</v-icon>
            </v-btn> -->

                <template v-if="isCanUpdate">
                  <v-btn
                    small
                    color="#ffa000"
                    @click="editRow(props.item)"
                    class="mr-1"
                  >
                    <v-icon color="#fff">mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>

                <template v-if="isCanDelete">
                  <v-btn
                    small
                    color="pink"
                    @click="deleteRow(props.item)"
                    class="mr-1"
                  >
                    <v-icon color="#fff">mdi-delete</v-icon>
                  </v-btn>
                </template>

                <template>
                  <SendNotificationDialog
                    :contentId="props.item.id"
                    :afterSubmit="() => {}"
                  />
                </template>
              </template>
            </template>

            <template v-slot:[`item.contentStatus`]="props">
              <v-btn
                target="_blank"
                icon
                small
                :href="
                  checkStatusIsPublish(props.item)
                    ? getPublicLink(props.item)
                    : null
                "
              >
                <v-icon>
                  {{
                    checkStatusIsPublish(props.item) ? 'mdi-eye' : 'mdi-eye-off'
                  }}
                </v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.language.title`]="props">
              <div
                class="d-flex flex-column"
                style="gap: 10px; font-size: 1rem; margin-bottom: 6px"
              >
                <div class="mt-1">
                  {{ getTitle(props.item) }}
                </div>
                <div class="d-flex flex-row" style="gap: 3px">
                  <v-chip
                    x-small
                    dir="ltr"
                    :class="getContentStatusColor(props.item)"
                  >
                    {{ getStatusName(props.item) }}
                  </v-chip>
                  <v-chip x-small>
                    <v-icon x-small class="ml-1">mdi-account</v-icon>
                    {{ getUserName(props.item) }}
                  </v-chip>

                  <v-chip x-small dir="ltr">
                    {{ $service.formatDate(props.item.creationTime) }}
                    <v-icon x-small class="ml-1">mdi-calendar</v-icon>
                  </v-chip>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- pagination -->
      <v-row class="mt-0" style="flex: 0">
        <v-col cols="12" class="d-flex justify-space-between align-center">
          <v-pagination
            v-model="options.pageIndex"
            :length="numberOfPages"
            total-visible="7"
          />
          <v-select
            v-model="options.pageSize"
            :items="[5, 10, 15, 20]"
            label="عدد الصفوف"
            solo
            dense
            hide-details="auto"
            item-color="white"
            class="flex-grow-0"
            style="width: 100px"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import qs from 'qs';
import { selectedLanguage } from '@/config/config';
import { mapActions } from 'vuex';
import {
  showSnackbarFailMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';
import { getDataFromJSON } from '@/components/BaseEditor.vue';
import { viewHTMLContent } from '@/utls/chkEditor';
import DefaultDialog from '@/components/DefaultDialog.vue';
import DynamicContentDetail from './crud/DynamicContentDetail.vue';
import LanguagesFilter from '@/components/LanguagesFilter.vue';
import DeletedFilter from '@/components/DeletedFilter.vue';
import SendNotificationDialog from '@/views/Notifications/dialog/SendNotificationDialog.vue';
import PermissionMixin from '@/mixins/PermissionMixin';
import { appPermissions } from '@/libs/auth/permissions/appPermissions';
import axios from 'axios';
import FeatureStickyFilter from './components/FeatureStickyFilter.vue';
// import utils from '@/utls/utils';

const initFilters = {
  title: '',
};

const initOptions = {
  pageIndex: 1,
  pageSize: 15,
  language: selectedLanguage,
  isDeleted: false,
  isFeature: false,
  isSticky: false,
  ...initFilters,
};

let ourGetRequest = null;

export default {
  props: ['title', 'contentTypeId', 'contentTypePermissions'],
  components: {
    LanguagesFilter,
    DynamicContentDetail,
    DefaultDialog,
    DeletedFilter,
    SendNotificationDialog,
    FeatureStickyFilter,
  },
  data() {
    return {
      sel: null,
      pages: [],
      total: 0,
      headers: [
        { text: 'id', value: 'id' },
        // { text: 'slug', value: 'slug', width: '150px' },
        // { text: 'slug', value: 'slug' },
        { text: '', value: 'contentStatus', width: '5px' },
        { text: 'العنوان', value: 'language.title' },
        // { text: 'التفاصيل', value: 'language.description' },
        // { text: 'الكلمات المفتاحية', value: 'language.keyword' },
        // { text: 'النوع', value: 'contentType.slug' },
        // { text: 'اسم النوع', value: 'contentTypeLanguage.name' },
        // { text: 'الصنف', value: 'category.slug' },
        { text: 'اسم الصنف', value: 'categoryNames' },
        { text: 'تاريخ النشر', value: 'publishDate', width: '100px' },
        { text: '', value: 'controls', sortable: false, width: '200px' },
        // { text: 'تاريخ الانشاء', value: 'creationTime' },
      ],
      options: { ...initOptions },
      filters: { ...initFilters },
      // for view edit and detail
      // ------------------------
      openToEdit: false,
      openToView: false,
      itemId: null,
      // ------------------------
      searchTimer: null,
    };
  },
  mixins: [
    PermissionMixin(appPermissions.dynamicContent, {
      name: 'dynamicContentPermissions',
    }),
  ],
  computed: {
    dynamicTitle() {
      if (this.title) return this.title;
      return 'المحتوى المتغير';
    },
    skipPerPage() {
      return this.options.pageSize * (this.options.pageIndex - 1);
    },
    numberOfPages() {
      const pages = Math.ceil(this.total / this.options.pageSize) || 1;
      return pages;
    },
    permissions() {
      if (this.contentTypePermissions != undefined) {
        return this.contentTypePermissions;
      }
      return this.dynamicContentPermissions || {};
    },
    isCanRead() {
      const { isCanRead = false } = this.permissions;
      return isCanRead;
    },
    isCanCreate() {
      const { isCanCreate = false } = this.permissions;
      return isCanCreate;
    },
    isCanUpdate() {
      const { isCanUpdate = false } = this.permissions;
      return isCanUpdate;
    },
    isCanDelete() {
      const { isCanDelete = false } = this.permissions;
      return isCanDelete;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions('confirm', ['openConfirm']),
    deleteRow(item) {
      this.openConfirm({
        message: 'هل انت متأكد من حذف المحتوى ؟',
        confirm: () => {
          return this.$http
            .delete('app/dynamic-content/' + item.id, {})
            .then(() => {
              this.getData();
              return true;
            })
            .catch((error) => {
              const response = error.response || {};
              const { data: { error: { code, message } = {} } = {} } = response;
              if (code == 'auth401') {
                var msg = 'غير مخول : لاتملك صلاحية الحذف';
                return showSnackbarWarningMessage(msg);
              }
              showSnackbarFailMessage('حدث خطأ اثناء عملية الحذف');
              return false;
            });
        },
      });
    },
    cancelDeleteRow(item) {
      this.openConfirm({
        message: 'هل انت متأكد من استعادة المحتوى ؟',
        confirm: () => {
          return this.$http
            .post('app/dynamic-content/' + item.id + '/cancel-delete/', {})
            .then(() => {
              this.getData();
              return true;
            })
            .catch((error) => {
              showSnackbarFailMessage('حدث خطأ اثناء عملية الاستعادة');
              return false;
            });
        },
      });
    },
    onCloseDialog() {
      this.openToEdit = false;
      this.openToView = false;
    },
    editRow(item) {
      this.openToEdit = true;
      this.itemId = item.id;
    },
    setItemID(id) {
      if (id) return this.editRow({ id });
      this.itemId = null;
      this.onCloseDialog();
    },
    viewRow(item) {
      // this.openToView = true;
      // this.itemId = item.id;
      const languageDtls = item?.dynamicContentLanguages[0] || {};
      const title = languageDtls.title;
      const url = '{url}-' + item.slug;
      const editorData = getDataFromJSON(languageDtls.contentJson);
      viewHTMLContent(title, url, editorData);
    },
    changeLanguage(language) {
      this.options.language = language;
    },
    changeIsDeleted(isDeleted) {
      this.options.isDeleted = isDeleted;
    },
    changeIsFeatureIsSticky({ isFeature, isSticky }) {
      this.options.isFeature = isFeature;
      this.options.isSticky = isSticky;
    },
    getData() {
      ourGetRequest = axios.CancelToken.source();

      this.$store.commit('loading/SET_SHOW', true);
      const requestConfig = {
        params: {
          contentTypeId: this.contentTypeId,
          skipCount: this.skipPerPage,
          maxResultCount: this.options.pageSize,
          language: this.options.language,
          title: this.options.title,
          isDeleted: this.options.isDeleted,
          isFeature: this.options.isFeature,
          isSticky: this.options.isSticky,
          includeSchema: false,
        },
        paramsSerializer: function (params) {
          const queryString = qs.stringify(params, { arrayFormat: 'brackets' });
          return queryString;
        },
        cancelToken: ourGetRequest.token,
      };

      this.$http
        .get('app/dynamic-content/by-filters', requestConfig)
        .then((response) => {
          const { items, totalCount } = response.data;

          const resultData = items.reduce((p, c) => {
            const language = c.dynamicContentLanguages[0];

            const categoryNames = c.categories.map(({ category }) => {
              const categoryLanguage =
                category && category.categoryLanguages[0];
              const { name = '' } = categoryLanguage || {};
              return name;
            });

            const contentTypeLanguage =
              c.contentType && c.contentType.contentTypeLanguages[0];

            const data = [
              ...p,
              { ...c, language, categoryNames, contentTypeLanguage },
            ];
            return data;
          }, []);
          this.pages = resultData;
          this.total = totalCount;
        })
        .catch((error) => {
          if (error.message === 'cancel_req') return;
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
        })
        .finally(() => this.$store.commit('loading/SET_SHOW', false));
    },
    searchUser() {},
    resetFilters() {
      const { languagesFilterRef, deletedFilterRef, featureStickyFilterRef } =
        this.$refs;
      languagesFilterRef && languagesFilterRef.reset();
      deletedFilterRef && deletedFilterRef.reset();
      featureStickyFilterRef && featureStickyFilterRef.reset();

      this.filters = { ...initFilters };
    },
    getTitle(item) {
      return item?.language?.title || '';
    },
    getContentStatusColor(item) {
      const { contentStatus } = item;
      const { slug } = contentStatus || {};
      if (slug === 'PublishStatus') return 'green white--text';
      if (slug === 'PendingStatus' || slug === 'CloseStatus')
        return 'red white--text';
      if (slug === 'DraftStatus') return 'yellow black--text';
      return 'grey';
    },
    getStatusName(item) {
      return item?.contentStatus?.contentStatusLanguages?.[0]?.name || '';
    },
    getUserName(item) {
      return item?.creator?.userName || '';
    },
    checkStatusIsPublish(item) {
      return item?.contentStatus?.slug == 'PublishStatus';
    },
    getPublicLink(item) {
      const { slug: categorySlug } = item?.categories?.at?.(0)?.category || {};
      const { slug: itemSlug } = item;

      if (!categorySlug || !itemSlug) return;

      return `http://164.92.187.207:5007/ar/category/${categorySlug}/${itemSlug}`;
    },
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    'filters.title': {
      handler() {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(
          () => Object.assign(this.options, this.filters),
          500,
        );
      },
    },
    contentTypeId() {
      if (ourGetRequest != null) ourGetRequest.cancel('cancel_req');
      this.pages = [];
      this.options = { ...initOptions };
      this.resetFilters();
    },
  },
};
</script>
