import { makeid } from '@/utls/jsCommon';

export class Tag {
  constructor({ id = null, tagLanguages = [] }) {
    this.id = id;
    this.slug = makeid(15);
    this.tagLanguages = tagLanguages;
  }

  static getTagName(tag) {
    if(!tag) return '';
    const { tagLanguages } = tag;
    const tagLanguage = tagLanguages[0] || {};
    const { name = '' } = tagLanguage;
    return name;
  }
}
